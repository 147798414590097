<template>
  <div class="app">
    <div class="common-content">
      <el-row>
        <el-col class="test-img">
          <img src="../assets/images/test-img.jpg" alt />
        </el-col>
        <el-col>
          <el-row class="test-title">
            <el-col>
              <h4>{{ detail && detail.paper.title || '' }}</h4>
              <p>
                <span>时间：{{ detail && detail.paper.time || 45 }}分钟</span>
                <span>标签：{{ detail && detail.tag.tag_name || '' }}</span>
              </p>
              <p><span>用时：{{ detail && detail.minutes }}分钟</span>
              <span>得分：{{ detail && detail.score }}</span></p>
              <p>{{ detail && detail.paper.desc }}</p>
            </el-col>
          </el-row>
          <el-row v-if="detail">
            <el-col v-for="(ques, key) in detail.snapshot" :key="key">
              <el-form >
                <el-form-item>
                  <h4>{{ ques.article_name }}</h4>
                </el-form-item>
                <el-form-item v-for="(item,k) in ques.lists" :key="k">
                  <p><i class="item-order">{{ k+1 }}</i><span>{{ item.title }} ({{ item.type === 0 ? '单选题' : '多选题'}} {{ques.score}}分)</span></p>
                  <el-radio-group v-if="item.type === 0" v-model="form[key][k]" size="small">
                    <el-radio v-for="(option, opk) in item.options" :label="option.answer" :key="opk">{{ option.answer }}</el-radio>
                  </el-radio-group>
                  <el-checkbox-group v-if="item.type === 1" v-model="form[key][k]" size="small">
                    <el-checkbox v-for="(option, opk) in item.options" :label="option.answer" :key="opk">{{ option.answer }}</el-checkbox>
                  </el-checkbox-group>
                  <p v-if="item.score <= 0"><i class="">正确答案</i><span v-for="(option, opk) in item.options" :key="opk">
                    <el-tag type="danger" v-if="option.is_correct">{{option.answer}}</el-tag>
                  </span></p>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col class="form-submit"><el-button type="warning" plain @click="back">返回</el-button></el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      minuts: 0,
      detail: null,
      butShow: true,
      index: 0,
      checks: [],
      form: []
    }
  },
  created: function () {
    this.getTest()
  },
  methods: {
    back () {
      this.$router.push({ name: 'UserExams' })
    },
    getTest () {
      this.$store.dispatch('getTest', this.$route.params.id).then(res => {
        this.detail = res
        for (let qk in res.snapshot) {
          let que = []
          for (let lk in res.snapshot[qk].lists) {
            let val = ''
            if (res.snapshot[qk].lists[lk].type === 1) {
              val = []
            }
            for (let ok in res.snapshot[qk].lists[lk].options) {
              if (res.snapshot[qk].lists[lk].type === 0) {
                if (res.snapshot[qk].lists[lk].options[ok].selected === 1) {
                  val = res.snapshot[qk].lists[lk].options[ok].answer
                  break
                }
              }
              if (res.snapshot[qk].lists[lk].type === 1) {
                if (res.snapshot[qk].lists[lk].options[ok].selected === 1) {
                  val.push(res.snapshot[qk].lists[lk].options[ok].answer)
                }
              }
            }
            que.push(val)
          }
          this.form.push(que)
        }
      }, () => {
        this.back()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less">
  .app {
    margin: 40px 0;
    .common-content {
      box-shadow: 0 0 30px @global-shadow-color;
      .test-img {
        img {
          width: 100%;
        }
      }
      .test-title {
        h4 {
          margin-bottom: 0;
        }
        p {
          span {
            margin: 0 10px;
          }
        }
      }
      .el-form {
        padding: 0 50px 20px;
        .el-form-item {
          text-align: left;
          .el-form-item__content{
            line-height: 20px
          }
          h4 {
            text-align: left;
            margin: 0;
          }
          .item-order {
            font-style:normal;
            padding: 0 10px;
            font-size: 12px;
            border-radius: 10px;
            margin-right: 5px;
            background: @global-title-p-color;
            color: @global-white;
          }
          p {
            display: inline-block;
            letter-spacing: 2px;
            margin: 0;
            margin-bottom:20px
          }
          .el-radio-group{
            display: block;
            /deep/.el-radio__input.is-checked .el-radio__inner{
              border-color:@global-submit-color;
              background: @global-submit-color;
            }
            /deep/.el-radio__input.is-checked+.el-radio__label{
              color:@global-text-color;
            }
          }
        }
        .form-submit{
          text-align: center;
          .el-button{
            background: @global-submit-color;
            color:@global-text-color;
            border-radius: 20px;
            padding:12px 50px
          }
        }
      }
    }
  }
</style>
